import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/high-bay-light/13(1).jpg"
import img2 from "../../images/high-bay-light/13(2).jpg"
import img3 from "../../images/high-bay-light/13(3).jpg"
import img4 from "../../images/high-bay-light/13(4).jpg"

import descImg1 from "../../images/high-bay-light/application.png"

import highBayLightSpecSheet from "../../images/high-bay-light/specSheet.svg"

const HighBayLightPage = () => {
  return (
    <Layout home={false}>
      <SEO title="HighBay Light" />
      <ProductPage
        productTitle="HighBay Light"
        showcase={
          <>
            <p style={{ lineHeight: "1.8rem" }}>
              High Street Lighting High bay lighting is the go-to solution for
              ensuring vivid and constant illumination through wide,
              high-ceiling indoor spaces. HSL high bay lights are being used in
              factories, branch stores, manufacturing fields, and sports halls.
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img1, img2, img3, img4]}
        description={
          <div style={{ width: "100%" }}>
            <ul>
              <li style={{fontSize: "1.2rem"}}>{">"}100 lumen/watt efficiency</li>
              <li style={{fontSize: "1.2rem"}}>Available in 4100-6500K CCT</li>
              <li style={{fontSize: "1.2rem"}}>Circular Pattern PC/PMMA optical enclosure</li>
              <li style={{fontSize: "1.2rem"}}>CISPR22 class B Complaint</li>
              <li style={{fontSize: "1.2rem"}}>ISO 9001:2008 (Manufacturing)</li>
            </ul>
            <div className="product-description-heading">Applications</div>
            <div className="product-description-heading-underline"></div>
            <div style={{maxWidth: "100%", display: "flex", justifyContent: "center"}}>
              <img src={descImg1} style={{maxWidth: "80%", maxHeight: "30rem"}}/>
            </div>
          </div>
        }
        technicalDetails={
          <div style={{ width: "180%", marginLeft: "-40%" }}>
            <img
              src={highBayLightSpecSheet}
              style={{ width: "100%" }}
            />
          </div>
        }
      />
    </Layout>
  )
}

export default HighBayLightPage
